import {
  Button,
  Modal,
  Select,
  Textarea,
  TextInput,
  FileUpload,
  UserAvatar,
  FormField,
  Form,
} from '@cg/module-frontend/src/components';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail, isValidUrl } from '@cg/common/src/utils';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { PaymentDetailMethodMap } from '@cg/module-frontend/src/utils/general.ts';
import { CreateHostFromBGRequest } from '~/generated/models/CreateHostFromBGRequest';
import { listHosts } from '~/generated/clients/background/hosts/BackgroundHosts.client';
import {
  createHostHook,
  updateHostSettingHook,
} from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import { User } from '~/generated/models/User';
import { PaymentDetailMethod } from '~/generated/models/PaymentDetailMethod';
import { listUsers } from '~/generated/clients/background/users/BackgroundUsers.client';

enum HostType {
  'Personal' = 'Personal',
  'Professional' = 'Professional',
}

type CreateHostProps = {
  user: User | null;
  show: boolean;
  onClose: () => void;
};

export default function CreateHost({ user, show, onClose }: CreateHostProps) {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [hostType, setHostType] = useState<HostType | null>(null);
  const [hostImage, setHostImage] = React.useState<string | null>(null);

  const getForm = () => ({
    ownerEmail: user?.email || '',
    imageUrl: user?.imageUrl || '',
    isPersonal: !!user,
    uniqueName: user ? `${user.firstName?.toLowerCase()}-host` : '',
    friendlyName: user ? `${user.firstName}'s Personal` : '',
    summary: '',
    description: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateHostFromBGRequest>({
    mode: 'onBlur',
    defaultValues: useMemo(() => getForm(), [user]),
  });

  const setType = (type: HostType) => {
    setValue('isPersonal', type === HostType.Personal);
    setHostType(type);
  };

  useEffect(() => {
    if (user) {
      reset(getForm);
      setType(HostType.Personal);
    }
  }, [user]);

  const { data: host, calling: creating, call: create } = createHostHook(false);
  const {
    data: updatedHost,
    calling: updating,
    call: update,
  } = updateHostSettingHook(false);

  const onSubmit: SubmitHandler<CreateHostFromBGRequest> = async (payload) => {
    const body = { ...payload };
    if (!body.paymentDetail?.address) {
      delete body.paymentDetail;
    }
    if (!body.email) {
      delete body.email;
    }

    await create({ body });
  };

  useEffect(() => {
    if (host?.imageUrl) {
      navigate(`/hosts/${host.id.getValue()}`);
    }
    if (updatedHost) {
      navigate(`/hosts/${updatedHost.id.getValue()}`);
    }
  }, [host, updatedHost]);

  const isStepOne = hostType === null;
  const isStepTwo = hostType !== null && !host;
  const isStepThree = !!host && hostType === HostType.Professional;

  return (
    <Modal
      show={show}
      onClose={() => {
        setHostType(null);
        reset();
        onClose();
      }}
    >
      <Modal.Header>
        Create a new{' '}
        {hostType === HostType.Personal && <strong>Personal </strong>}
        {hostType === HostType.Professional && <strong>Professional </strong>}
        Host
      </Modal.Header>
      <Modal.Body>
        {isStepOne && (
          <div>
            <button
              className="flex flex-col w-full justify-center items-center text-center p-10 bg-secondary rounded-t font-semibold"
              type="button"
              onClick={() => setType(HostType.Personal)}
            >
              Make a Personal Host
            </button>
            <button
              className="flex flex-col w-full ustify-center items-center text-center bg-primary p-10 rounded-b font-semibold"
              type="button"
              onClick={() => setType(HostType.Professional)}
            >
              Make a Professional Host
            </button>
          </div>
        )}

        {isStepTwo && (
          <Form className="space-y-4" register={register} errors={errors}>
            <FormField
              required
              register={register}
              id="ownerEmail"
              label="Owner Email"
              registerOptions={{
                validate: async (email: string) => {
                  if (!isValidEmail(email)) {
                    return 'Invalid email address';
                  }

                  const response = await listUsers({
                    filters: { email },
                  });

                  if (!response.succeeded) {
                    return response.payload.message;
                  }

                  if (response.payload.result.length > 1) {
                    return 'Multiple users found';
                  }

                  const match = response.payload.result[0];
                  if (!match) {
                    return 'User not found';
                  }

                  if (hostType === HostType.Personal) {
                    setValue('imageUrl', match.imageUrl, {
                      shouldDirty: true,
                    });
                  }

                  return true;
                },
              }}
            >
              <TextInput placeholder="Owner's Email Address" />
            </FormField>
            {hostType === HostType.Personal && (
              <FormField
                required
                register={register}
                id="imageUrl"
                label="Image (readonly)"
                registerOptions={{
                  validate: async (imageUrl?: string) => {
                    return isValidUrl(imageUrl) || 'Invalid URL';
                  },
                }}
              >
                <TextInput placeholder="Host's Image URL" />
              </FormField>
            )}

            <FormField
              required
              register={register}
              id="uniqueName"
              label="Unique Name (32 max)"
              registerOptions={{
                validate: async (uniqueName: string) => {
                  if (!uniqueName) {
                    return 'Please enter a unique name';
                  }

                  const response = await listHosts({
                    filters: { uniqueName },
                  });

                  if (!response.succeeded) {
                    return response.payload.message;
                  }

                  if (response.payload.result.length > 0) {
                    return 'Host with this name already exists';
                  }

                  return true;
                },
              }}
            >
              <TextInput placeholder="Unique name of the host" />
            </FormField>

            <FormField
              required
              register={register}
              id="friendlyName"
              label="Friendly Name (64 max)"
              registerOptions={{
                validate: async (friendlyName: string) => {
                  if (!friendlyName) {
                    return 'Please enter a feature name';
                  }

                  if (friendlyName.length > 64) {
                    return 'Feature name must be less than 64 characters';
                  }

                  return true;
                },
              }}
            >
              <TextInput
                placeholder="Friendly name of the host"
                maxLength={64}
              />
            </FormField>

            <FormField
              required
              register={register}
              id="summary"
              label="Summary (128 max)"
              registerOptions={{
                validate: async (summary: string) => {
                  if (!summary) {
                    return 'Please enter a summary';
                  }

                  if (summary.length > 128) {
                    return 'Summary must be less than 128 characters';
                  }

                  return true;
                },
              }}
            >
              <Textarea
                placeholder="Summary of the Host"
                rows={2}
                maxLength={128}
              />
            </FormField>

            <FormField
              register={register}
              id="about"
              label="About"
              registerOptions={{
                validate: async (about?: string) => {
                  if (!about) {
                    return true;
                  }

                  if (about.length > 512) {
                    return 'Summary must be less than 512 characters';
                  }

                  return true;
                },
              }}
            >
              <Textarea rows={4} placeholder="Access to using Playground" />
            </FormField>

            <FormField
              register={register}
              id="email"
              label="Business Email"
              registerOptions={{
                validate: async (email?: string) => {
                  if (!email) {
                    return true;
                  }

                  if (!isValidEmail(email)) {
                    return 'Invalid email address';
                  }

                  return true;
                },
              }}
            >
              <TextInput
                type="email"
                placeholder="The business email of the host"
              />
            </FormField>

            <FormField
              register={register}
              id="paymentDetail.method"
              label="Payment Method"
            >
              <Select>
                {Object.keys(PaymentDetailMethod).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {PaymentDetailMethodMap.get(key as PaymentDetailMethod)}
                    </option>
                  );
                })}
              </Select>
            </FormField>

            <FormField
              register={register}
              id="paymentDetail.address"
              label="Payment Address"
              registerOptions={{
                validate: (address: string) => {
                  if (!address) {
                    return true;
                  }

                  if (address.length < 1) {
                    return 'Please enter a valid address';
                  }

                  return true;
                },
              }}
            >
              <TextInput placeholder="Enter your payment address - usually this is your email address" />
            </FormField>
          </Form>
        )}

        {isStepThree && (
          <div className="space-y-4">
            Update the Host Image
            <div className="flex flex-col justify-center items-center">
              <FileUpload
                background
                onUploading={() => setIsUploading(true)}
                onUploaded={(response) => {
                  setIsUploading(false);
                  setValue('imageUrl', response.imageUrl, {
                    shouldDirty: true,
                  });
                  setHostImage(response.imageUrl);
                }}
                ids={{
                  hostId: host?.id,
                }}
                onUploadFailed={() => {
                  /* noop */
                }}
                type="Host"
                maxSize={5}
                minWidth={360}
                minHeight={360}
              >
                <div
                  className={classNames(
                    'flex items-end justify-end w-fit h-fit',
                    {
                      'animate-[pulse_1.2s_infinite]': isUploading,
                    },
                  )}
                >
                  {/* @ts-ignore */}
                  <UserAvatar host={getValues()} imageSize="xlg" />
                </div>
              </FileUpload>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isStepTwo && (
          <Button
            color="primary"
            className="ml-auto"
            disabled={!isValid || creating}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        )}
        {isStepThree && (
          <Button
            color="primary"
            className="ml-auto"
            disabled={hostImage === null || updating}
            onClick={() => {
              if (host?.id && hostImage) {
                const body = { ...host };
                if (!body.email) {
                  delete body.email;
                }

                update({
                  ids: {
                    hostId: host.id,
                  },
                  body: {
                    ...body,
                    imageUrl: hostImage,
                  },
                });
              }
            }}
          >
            Upload Photo
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import React from 'react';
import FormField, { Props as FormFieldProps } from './FormField';

type Props<TFieldValues extends FieldValues> = {
  className?: string;
  errors?: FieldErrors<TFieldValues>;
  register: UseFormRegister<TFieldValues>;
  children: React.ReactNode;
};

function Form<TFieldValues extends FieldValues>({
  errors,
  className,
  register,
  children,
}: Props<TFieldValues>) {
  const processChildren = (nodes: React.ReactNode): React.ReactNode => {
    return React.Children.map(nodes, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === FormField) {
          // Automatically infer and enforce the generic type for FormField
          return React.cloneElement(
            child as React.ReactElement<FormFieldProps<TFieldValues, any>>,
            {
              errors,
              register,
            },
          );
        }

        // Recursively process nested children
        if (child.props?.children) {
          return React.cloneElement(child, {
            ...child.props,
            children: processChildren(child.props.children),
          });
        }
      }

      return child;
    });
  };

  const extendedChildren = processChildren(children);

  return <form className={className}>{extendedChildren}</form>;
}

Form.displayName = 'Form';

export default Form;

/* eslint-disable */

import { http, HttpResult } from '~/core/http';


import { CreateExperienceRequest } from '../../../../models/CreateExperienceRequest';
import { Experience } from '../../../../models/Experience';
import { HostId } from '@cg/common/src/ids/HostId';
import { CreateQuestionRequest } from '../../../../models/CreateQuestionRequest';
import { Question } from '../../../../models/Question';
import { ExperienceId } from '@cg/common/src/ids/ExperienceId';
import { QuestionId } from '@cg/common/src/ids/QuestionId';
import { Address } from '../../../../models/Address';
import { ExperienceConfirmation } from '../../../../models/ExperienceConfirmation';
import { Page } from '@cg/common/src/paging/Page';
import { AnswerWithUser } from '../../../../models/AnswerWithUser';
import { Answer } from '../../../../models/Answer';
import { AllExperienceConfirmation } from '../../../../models/AllExperienceConfirmation';
import { ExperienceWithTickets } from '../../../../models/ExperienceWithTickets';
import { ExperienceStatus } from '../../../../models/ExperienceStatus';
import { UpdateAddressRequest } from '../../../../models/UpdateAddressRequest';
import { UpdateExperienceConfirmationRequest } from '../../../../models/UpdateExperienceConfirmationRequest';
import { UpdateExperienceRequest } from '../../../../models/UpdateExperienceRequest';
import { UpdateQuestionRequest } from '../../../../models/UpdateQuestionRequest';
import { CreatePhotoPreSignedUrlRequest } from '../../../../models/CreatePhotoPreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../../models/PreSignedUrlResponse';
import { UserWithTicket } from '../../../../models/UserWithTicket';
import { User } from '../../../../models/User';

export type CreateExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: CreateExperienceRequest,
}

/**
 * @summary Endpoint for creating a new Experiences for this Host.
 * @param {CreateExperienceArgs} args
 */
export const createExperience = (args: CreateExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type CreateQuestionArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    body: CreateQuestionRequest,
}

/**
 * <p>API endpoints for CRUD operations Question</p>
 * @summary Creates a Question
 * @param {CreateQuestionArgs} args
 */
export const createQuestion = (args: CreateQuestionArgs): Promise<HttpResult<Question>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/questions', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DeleteExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for deleting an Experience.
 * @param {DeleteExperienceArgs} args
 */
export const deleteExperience = (args: DeleteExperienceArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DeleteQuestionArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
        questionId: QuestionId,
    },
}

/**
 * <p>API endpoints for CRUD operations Question</p>
 * @summary Deletes a Question
 * @param {DeleteQuestionArgs} args
 */
export const deleteQuestion = (args: DeleteQuestionArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/questions/{questionId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DuplicateExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Duplicates an Experience
 * @param {DuplicateExperienceArgs} args
 */
export const duplicateExperience = (args: DuplicateExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/duplicate', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchAddressArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting an Experience address.
 * @param {FetchAddressArgs} args
 */
export const fetchAddress = (args: FetchAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/address', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting an Experience by id.
 * @param {FetchExperienceArgs} args
 */
export const fetchExperience = (args: FetchExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchFailureConfirmationArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting the confirmation of the Experience.
 * @param {FetchFailureConfirmationArgs} args
 */
export const fetchFailureConfirmation = (args: FetchFailureConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/confirmations/failure', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchSuccessConfirmationArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting the confirmation of the Experience.
 * @param {FetchSuccessConfirmationArgs} args
 */
export const fetchSuccessConfirmation = (args: FetchSuccessConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/confirmations/success', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchWaitlistConfirmationArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting the confirmation of the Experience.
 * @param {FetchWaitlistConfirmationArgs} args
 */
export const fetchWaitlistConfirmation = (args: FetchWaitlistConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/confirmations/waitlist', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListAnswersArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for CRUD operations Question</p>
 * @summary List all the answers
 * @param {ListAnswersArgs} args
 */
export const listAnswers = (args: ListAnswersArgs): Promise<HttpResult<Page<AnswerWithUser>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/answers', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListConfirmationsArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Returns all the confirmation of the Experience
 * @param {ListConfirmationsArgs} args
 */
export const listConfirmations = (args: ListConfirmationsArgs): Promise<HttpResult<AllExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/confirmations', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListExperiencesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>Returns the list of Experiences that are owned by this Host. This will include both published and draft mode.</p>
 * @summary Endpoint for returning list of Experiences that belong to this Host.
 * @param {ListExperiencesArgs} args
 */
export const listExperiences = (args: ListExperiencesArgs): Promise<HttpResult<Page<Experience>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListExperiencesFilteredArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        status?: ExperienceStatus,
        experienceSeriesId?: string,
        previousOnly?: boolean,
        upcomingOnly?: boolean,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of Experiences but using filters. This is used for the Dashboard
 * @param {ListExperiencesFilteredArgs} args
 */
export const listExperiencesFiltered = (args: ListExperiencesFilteredArgs): Promise<HttpResult<Page<ExperienceWithTickets>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences-filtered', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListQuestionsArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for CRUD operations Question</p>
 * @summary Returns the list of Questions
 * @param {ListQuestionsArgs} args
 */
export const listQuestions = (args: ListQuestionsArgs): Promise<HttpResult<Page<Question>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/questions', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type PublishExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * @summary Publishes the Experience.
 * @param {PublishExperienceArgs} args
 */
export const publishExperience = (args: PublishExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/publish', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateAddressArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    body: UpdateAddressRequest,
}

/**
 * @summary Endpoint for updating the Address of Experience.
 * @param {UpdateAddressArgs} args
 */
export const updateAddress = (args: UpdateAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/address', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateConfirmationArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    body: UpdateExperienceConfirmationRequest,
}

/**
 * @summary Updates the confirmation of the Experience
 * @param {UpdateConfirmationArgs} args
 */
export const updateConfirmation = (args: UpdateConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/confirmations', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    body: UpdateExperienceRequest,
}

/**
 * @summary Endpoint for updating the Experience.
 * @param {UpdateExperienceArgs} args
 */
export const updateExperience = (args: UpdateExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateQuestionArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
        questionId: QuestionId,
    },
    body: UpdateQuestionRequest,
}

/**
 * <p>API endpoints for CRUD operations Question</p>
 * @summary Updates a Question
 * @param {UpdateQuestionArgs} args
 */
export const updateQuestion = (args: UpdateQuestionArgs): Promise<HttpResult<Question>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/questions/{questionId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    body: CreatePhotoPreSignedUrlRequest,
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo for an Experience
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/photos', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type WaitlistedUsersArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API for fetching the list of users in a waitlist</p>
 * @summary Returns the list of Users
 * @param {WaitlistedUsersArgs} args
 */
export const waitlistedUsers = (args: WaitlistedUsersArgs): Promise<HttpResult<Page<UserWithTicket>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/experiences/{experienceId}/waitlists', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}

import { ILogObj, Logger as Log, ILogObjMeta } from 'tslog';
import { useRollbar } from '@rollbar/react';
import { boxConfig } from '../config';

export const Logger = (name: string) => {
  return new Log({ name });
};

export const useLogger = (name: string) => {
  if (boxConfig.isLocal) {
    return Logger(name);
  }

  const rollbar = useRollbar();

  const rollbarTransport = (logObject: ILogObj & ILogObjMeta) => {
    const message =
      logObject?.[0] || logObject.message || 'Unknown log message';
    const context = {
      ...logObject,
      logLevel: logObject.logLevelName || logObject.logLevel,
      name: logObject.name,
      path: logObject.path,
    };

    // eslint-disable-next-line no-underscore-dangle
    switch (logObject._meta.logLevelId) {
      case 5: // error
      case 6: // fatal
        rollbar.error(message, context);
        break;
      case 4: // warn
        rollbar.warning(message, context);
        break;
      case 3: // info
        rollbar.info(message, context);
        break;
      case 2: // debug
      case 1: // trace
        rollbar.debug(message, context);
        break;
      default:
        rollbar.log(message, context);
    }
  };

  return new Log({
    name,
    minLevel: 3,
    attachedTransports: [rollbarTransport],
  });
};

/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The reason for the refund</p>
 */
export enum RefundReason {
	ExperienceReschedule = 'ExperienceReschedule',
	ExperienceCancellation = 'ExperienceCancellation'
}


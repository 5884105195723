import { FlowbiteTooltipTheme } from 'flowbite-react';

const tooltip: FlowbiteTooltipTheme = {
  target: '',
  animation: 'transition-opacity',
  arrow: {
    base: 'absolute z-10 h-2 w-2 rotate-45',
    style: {
      dark: 'bg-grey',
      light: 'bg-white',
      auto: 'bg-white',
    },
    placement: '-4px',
  },
  base: 'absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm',
  hidden: 'invisible opacity-0',
  style: {
    dark: 'bg-grey text-black',
    light: 'border border-gray-200 bg-white text-black',
    auto: 'border border-gray-200 bg-white text-black',
  },
  content: 'relative z-20',
};

export default tooltip;

/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { fetchTicket, FetchTicketArgs, fetchTicketTier, FetchTicketTierArgs, refundTicket, RefundTicketArgs } from './BackgroundTickets.client';

export const fetchTicketHook = (immediateCall: boolean = true, initArgs?: FetchTicketArgs) => {
  return useHttp(fetchTicket, { immediateCall, initArgs })
};
export const fetchTicketTierHook = (immediateCall: boolean = true, initArgs?: FetchTicketTierArgs) => {
  return useHttp(fetchTicketTier, { immediateCall, initArgs })
};
export const refundTicketHook = (immediateCall: boolean = true, initArgs?: RefundTicketArgs) => {
  return useHttp(refundTicket, { immediateCall, initArgs })
};

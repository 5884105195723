import { FlowbiteTextInputTheme } from 'flowbite-react';

const textInput: FlowbiteTextInputTheme = {
  base: 'flex w-full',
  addon:
    'inline-flex items-center rounded-l border border-r-0 border-black disabled:opacity-50 bg-grey-lighter px-3 w-fit',
  field: {
    base: 'flex flex-grow',
    // @ts-ignore
    input: {
      base: '!bg-red disabled:bg-transparent w-full disabled:cursor-not-allowed disabled:opacity-50',
      colors: {
        gray: 'w-full border-black bg-white text-black placeholder-grey focus:border-primary focus:ring-primary text-black placeholder-black-lighter',
        info: 'w-full border-black bg-white text-black placeholder-grey focus:border-primary focus:ring-primary text-black placeholder-black-lighter',
        primary:
          'w-full border-black bg-white text-black placeholder-grey focus:border-primary focus:ring-primary text-black placeholder-black-lighter',
        secondary:
          'border-transparent bg-white  text-black focus:border-primary focus:ring-transparent',
        transparent:
          'border-transparent bg-transparent border-none border-grey-darker text-black text-center focus:border-primary focus:ring-transparent',
        failure:
          'border-failure text-failure placeholder-failure focus:border-failure focus:ring-failure',
        warning:
          'border-warning bg-warning text-warning placeholder-warning focus:border-warning focus:ring-warning',
        success:
          'border-success bg-success text-success placeholder-success focus:border-success focus:ring-success',
      },
      withAddon: {
        on: 'rounded-r border-l-0',
        off: 'rounded border',
      },
      sizes: {
        sm: 'p-2 sm:text-xs',
        md: 'p-2.5 text-sm',
        lg: 'p-4 sm:text-base',
      },
    },
  },
};

export default textInput;

import {
  Article,
  Table,
  TableHeaderItem,
  TableRowItem,
  UserAvatar,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  Button,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useForm } from '@cg/module-frontend/src/hooks';
import { TicketTier } from '~/generated/models/TicketTier';
import { UserWithTicket } from '~/generated/models/UserWithTicket.ts';
import { RefundReason } from '~/generated/models/RefundReason.ts';
import { refundTicketHook } from '~/generated/clients/background/tickets/BackgroundTickets.hooks.ts';

type GuestsDetailsProps = {
  users: UserWithTicket[];
  tiers: TicketTier[];
};
export default function GuestsDetails({ users, tiers }: GuestsDetailsProps) {
  const { register, reset, handleSubmit } = useForm<{ refund: RefundReason }>({
    mode: 'onSubmit',
    defaultValues: {
      refund: RefundReason.ExperienceCancellation,
    },
  });
  const {
    call: refundTicket,
    calling: refunding,
    error,
  } = refundTicketHook(false);
  const [refund, setRefund] = React.useState<UserWithTicket | null>(null);
  const navigate = useNavigate();
  const headers: TableHeaderItem[] = [
    '',
    'Name',
    'Email',
    'Phone Number',
    'Diet',
    'Tier',
    'Payment Status',
    'Actions',
  ];

  const onSubmit = handleSubmit(async (data) => {
    if (!refund?.ticket) {
      return;
    }

    const response = await refundTicket({
      ids: {
        ticketId: refund?.ticket?.id,
      },
      body: {
        reason: data.refund,
      },
    });

    if (response.succeeded) {
      setRefund(null);
      reset();
    }
  });

  const rows: TableRowItem[] = users.map((user, index) => {
    const tier = tiers?.find((t) => user.ticket?.ticketTierId?.isEqual(t.id));
    const status = user.ticket?.status?.toString() || 'Unknown';

    return {
      onClick: () => navigate(`/users/${user.id}`),
      className: 'bg-white border-grey-darker cursor-pointer',
      key: user.id.getValue(),
      cells: [
        index + 1,
        <div className="flex flex-row items-center">
          <UserAvatar user={user} iconSize="md" expandable />
          <span className="ml-2 whitespace-nowrap">
            {user.firstName} {user.lastName}
          </span>
        </div>,
        user.email,
        user.phoneNumber,
        user.diet.join(','),
        {
          label: (
            <span className="bg-secondary rounded text-xs font-semibold p-1 px-2 text-primary cursor-pointer whitespace-nowrap">
              {tier?.name}
            </span>
          ),
        },
        {
          label: (
            <span className="bg-grey rounded text-xs font-semibold p-1 px-2 text-black whitespace-nowrap">
              {status}
            </span>
          ),
        },
        {
          label: (
            <button
              className="text-primary underline"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setRefund(user);
              }}
            >
              Refund
            </button>
          ),
        },
      ],
    };
  });

  return (
    <Article>
      <Modal show={!!refund} size="md" onClose={() => setRefund(null)}>
        <ModalHeader>Refund Ticket</ModalHeader>
        <ModalBody className="flex flex-col space-y-2">
          <p>
            You want to refund the ticket for {refund?.firstName}{' '}
            {refund?.lastName}.
          </p>
          <Select id="refund" className="w-full" {...register('refund')}>
            {Object.keys(RefundReason).map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </Select>
          <p className="ml-2 text-failure">{error?.message?.toString()}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={onSubmit}
            loading={refunding}
            disabled={refunding}
          >
            Refund
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <h2>Guests</h2>
        <Table headers={headers} rows={rows} />
      </Card>
    </Article>
  );
}

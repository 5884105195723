import { useMatches } from 'react-router';
import * as React from 'react';
import { ReactElement } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import NavigationLinks, { Nav } from './NavigationLinks';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  GetInTouchIcon,
  MenuIcon,
} from '../../icons';
import { boxConfig } from '../../config';
import { useScreenSize } from '../../hooks';
import { useAuth } from '../../core/auth/AuthContext';

type LeftNavigationProps = {
  logo: ReactElement<HTMLImageElement>;
  topNav: ReactElement | ReactElement[];
  navs: Nav[];
  actionBtn?: ReactElement;
};

function LeftNavigation({
  navs,
  logo,
  topNav,
  actionBtn,
}: LeftNavigationProps) {
  const { hasFlag } = useAuth();
  const { isMobile } = useScreenSize();
  const matches = useMatches();
  const [isCollapsed, setCollapsed] = React.useState(false);
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const filteredNavs = navs.filter((nav) => {
    if (!nav.featureFlags || nav.featureFlags.length === 0) {
      return true;
    }

    return nav.featureFlags?.every((flag) => hasFlag(flag));
  });

  if (isMobile) {
    return (
      <>
        {!isMenuOpen && (
          <button
            type="button"
            className="fixed bottom-4 left-4 z-50 p-2 bg-primary rounded-full shadow-lg"
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon className="text-white size-8" />
          </button>
        )}

        <div
          className={classNames(
            'fixed top-0 h-screen z-40 transition-transform',
            {
              'translate-x-0': isMenuOpen,
              '-translate-x-full': !isMenuOpen,
              'min-w-56': !isCollapsed,
            },
          )}
        >
          <div className="flex flex-col justify-center border-r border-grey-darker h-full pt-4 bg-grey">
            <div
              className={classNames('flex', {
                'px-4': !isCollapsed,
                'px-2': isCollapsed,
              })}
            >
              {isCollapsed ? logo : topNav}
            </div>

            {!isCollapsed && actionBtn && (
              <div className="flex flex-col w-full px-4 self-start mt-11 text-base">
                {actionBtn}
              </div>
            )}

            <div className="flex flex-col w-full self-start mt-4 text-base">
              <NavigationLinks
                navs={filteredNavs}
                matches={matches}
                isCollapsed={isCollapsed}
              />
            </div>

            <div className="flex-grow" />

            <footer className="flex flex-col px-4 mb-4">
              <div className="flex justify-between">
                <ul className="space-y-2.5">
                  <li className="flex gap-2.5 text-base font-semibold text-black-lighter items-center">
                    <Link
                      className="flex gap-2.5"
                      to={`${boxConfig.baseUrls.commonGround}/contact-us`}
                      target="_blank"
                    >
                      <GetInTouchIcon className="size-6" />
                      {!isCollapsed && <div>Get in Touch</div>}
                    </Link>
                  </li>
                </ul>

                {isMenuOpen && (
                  <button
                    type="button"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    className={classNames(
                      'flex h-12 -mr-8 text-base font-medium whitespace-nowrap bg-white rounded border border-solid border-grey-darker px-2',
                    )}
                  >
                    <ChevronLeftIcon className="shrink-0 aspect-1 size-4 my-auto" />
                  </button>
                )}
              </div>
            </footer>
          </div>
        </div>

        {isMenuOpen && (
          <div
            className="fixed top-0 left-0 w-full h-full z-30 bg-black bg-opacity-50"
            onClick={() => setMenuOpen(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setMenuOpen(false);
              }
            }}
            role="button"
            tabIndex={0}
          />
        )}
      </>
    );
  }

  return (
    <div
      className={classNames(
        'sticky top-0 h-screen transition-all duration-300',
        {
          'w-60': !isCollapsed,
          'w-20': isCollapsed,
        },
      )}
    >
      <div className="flex flex-col justify-center border-r border-grey-darker h-full pt-4 bg-grey transition-all duration-300">
        <div
          className={classNames('flex transition-all duration-300', {
            'px-3': !isCollapsed,
            'px-2': isCollapsed,
          })}
        >
          {isCollapsed ? logo : topNav}
        </div>

        {!isCollapsed && actionBtn && (
          <div className="flex flex-col w-full px-2 self-start mt-11 text-base transition-all duration-300">
            {actionBtn}
          </div>
        )}

        <div className="flex flex-col w-full self-start mt-4 text-base transition-all duration-300">
          <NavigationLinks
            navs={filteredNavs}
            matches={matches}
            isCollapsed={isCollapsed}
          />
        </div>

        <div className="flex-grow transition-all duration-300" />

        <footer className="flex flex-col px-4 mb-4 transition-all duration-300">
          <div className="flex justify-between transition-all duration-300">
            <ul className="space-y-2.5 transition-all duration-300">
              <li className="flex gap-2.5 text-base font-semibold text-black-lighter items-center transition-all duration-300">
                <Link
                  className="flex gap-2.5 transition-all duration-300"
                  to={`${boxConfig.baseUrls.commonGround}/contact-us`}
                  target="_blank"
                >
                  <GetInTouchIcon className="size-6" />
                  {!isCollapsed && <div>Get in Touch</div>}
                </Link>
              </li>
            </ul>

            <button
              type="button"
              onClick={() => setCollapsed(!isCollapsed)}
              className={classNames(
                'flex h-12 -mr-8 text-base font-medium whitespace-nowrap bg-white rounded border border-solid border-grey-darker px-2 transition-all duration-300',
              )}
            >
              {isCollapsed ? (
                <ChevronRightIcon className="shrink-0 aspect-1 size-4 my-auto transition-all duration-300" />
              ) : (
                <ChevronLeftIcon className="shrink-0 aspect-1 size-4 my-auto transition-all duration-300" />
              )}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default LeftNavigation;

import {
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
  useJsApiLoader,
  Libraries,
} from '@react-google-maps/api';
import { useState } from 'react';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { Loader } from '../loader';

const options = {
  strokeColor: 'transparent',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#D8607A',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

type MapProps = {
  latitude: number;
  longitude: number;
  address: AddressOrCoordinate | null;
  secret: boolean;
};

const libraries = ['places'] as Libraries;

function Map({ latitude, longitude, address, secret }: MapProps) {
  const [showInfo, setShowInfo] = useState(false);
  const zoom = secret ? 12 : 13;
  const center = { lat: latitude, lng: longitude };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return (
    <Loader loading={!isLoaded}>
      <GoogleMap
        mapContainerClassName="h-60 w-full rounded"
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
          fullscreenControl: false,
        }}
        center={center}
        zoom={zoom}
      >
        InfoWindow
        {secret ? (
          <Circle center={center} radius={750} options={options} />
        ) : (
          <Marker position={center} onClick={() => setShowInfo(false)}>
            {!secret && showInfo && address && (
              <InfoWindow onCloseClick={() => setShowInfo(false)}>
                <>
                  <strong>{address.name}</strong> - {address.street1},{' '}
                  {address.city}, {address.state} {address.code}
                </>
              </InfoWindow>
            )}
          </Marker>
        )}
      </GoogleMap>
    </Loader>
  );
}

export default Map;

import React, { useState } from 'react';
import { Modal, ModalBody } from 'flowbite-react';
import { Size, sizeToPx } from '../../constant';
import { ProfileIcon } from '../../icons';
import { Host } from '~/generated/models/Host';
import { colors, tw } from '../../index';
import Img from '../img';

type User = {
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
};

const userToColor = (user: User) => {
  const color = colors.stringToColor(`${user.firstName}${user.lastName}`);

  return {
    bg: color.slice(1),
    text: colors.colorToText(color),
  };
};

type AvatarViewProps = {
  user?: User | null;
  host?: Host | null;
  iconSize?: Size;
  imageSize?: Size;
  className?: string;
};

type UserAvatarProps = AvatarViewProps & {
  expandable?: boolean;
};

function AvatarView({
  user,
  host,
  iconSize = 'lg',
  imageSize = 'md',
  className,
}: AvatarViewProps) {
  if (user?.imageUrl) {
    return (
      <div
        style={{
          width: sizeToPx[imageSize],
          height: sizeToPx[imageSize],
        }}
        className={`h-[${sizeToPx[imageSize]}px] w-[${sizeToPx[imageSize]}px]`}
      >
        <Img
          className={tw('rounded-full w-full h-full', className)}
          src={user.imageUrl}
          alt={user.firstName || 'Person'}
        />
      </div>
    );
  }

  if (host?.imageUrl) {
    return (
      <div
        style={{
          width: sizeToPx[imageSize],
          height: sizeToPx[imageSize],
        }}
        className={`h-[${sizeToPx[imageSize]}px] w-[${sizeToPx[imageSize]}px]`}
      >
        <Img
          className={tw('rounded-full', className)}
          src={host.imageUrl}
          alt={host.friendlyName}
        />
      </div>
    );
  }

  if (user?.firstName && user.lastName) {
    const color = userToColor(user);
    return (
      <div
        className="rounded-full tracking-tight flex items-center justify-center"
        style={{
          width: sizeToPx[iconSize],
          height: sizeToPx[iconSize],
          backgroundColor: `#${color.bg}`,
        }}
      >
        <span
          className={`${iconSize === '2xlg' ? 'text-8xl' : 'text-base'} font-semibold`}
          style={{
            color: color.text,
          }}
        >
          {user?.firstName.charAt(0)}
          {user?.lastName.charAt(0)}
        </span>
      </div>
    );
  }

  return <ProfileIcon size={sizeToPx[iconSize]} className={className} />;
}

/**
 * Returns the user image or a default user icon
 */
function UserAvatar({
  user,
  host,
  iconSize = 'lg',
  imageSize = 'md',
  className,
  expandable,
}: UserAvatarProps) {
  const [modalOpen, setOpen] = useState(false);
  const onClick = () => {
    if (expandable) {
      setOpen(true);
    }
  };

  return (
    <div role="button" tabIndex={0} onKeyDown={() => null} onClick={onClick}>
      {AvatarView({ user, host, iconSize, imageSize, className })}
      {modalOpen && (
        <Modal
          dismissible
          show={modalOpen}
          position="center"
          onClose={() => setOpen(false)}
          className="z-[2000]"
          theme={{
            content: {
              base: 'bg-black-800/50 w-fit h-fit',
              inner: 'bg-black-800/50 w-fit h-fit',
            },
          }}
        >
          <ModalBody>
            <div className="flex items-center justify-center">
              {AvatarView({
                user,
                host,
                iconSize: '2xlg',
                imageSize: '2xlg',
                className,
              })}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

export default UserAvatar;

import { FlowbiteAccordionTheme } from 'flowbite-react';

const accordion: FlowbiteAccordionTheme = {
  root: {
    base: 'border-grey',
    flush: {
      off: 'rounded border',
      on: 'border-b',
    },
  },
  content: {
    base: 'p-2 first:rounded-t last:rounded-b',
  },
  title: {
    arrow: {
      base: 'h-6 w-6 shrink-0',
      open: {
        off: '',
        on: 'rotate-180',
      },
    },
    base: 'flex w-full items-center justify-between p-2 text-left text-black-lighter first:rounded-t last:rounded-b !text-sm ',
    flush: {
      off: 'hover:bg-grey',
      on: 'bg-transparent',
    },
    heading: '',
    open: {
      off: '',
      on: '',
    },
  },
};

export default accordion;

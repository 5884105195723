import {
  Button as FBButton,
  ButtonProps as FBButtonProps,
  Spinner,
} from 'flowbite-react';
import React, { type ElementType, MouseEvent, useState } from 'react';
import { ToolTip } from '../../tooltip';
// eslint-disable-next-line import/no-cycle
import ButtonConfirmation, { ConfirmProps } from './ButtonConfirmation';

export type ButtonProps<T extends ElementType = 'button'> = FBButtonProps<T> & {
  loading?: boolean;
  stopPropagation?: boolean;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  toolTip?: React.ReactNode;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<boolean | string | void> | void;
  confirm?: ConfirmProps;
};

export default function Button({
  loading,
  children,
  icon,
  iconEnd,
  onClick,
  toolTip,
  stopPropagation,
  className,
  confirm,
  ...props
}: ButtonProps) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  if (loading) {
    props.disabled = true;
  }

  if (React.isValidElement(children)) {
    const originalClassName = children.props.className || '';
    children = React.cloneElement(children, {
      ...props,
      // @ts-ignore
      className: `${originalClassName} flex items-center`,
    });
  }

  const getChild = () => {
    return (
      <>
        {icon}
        {children}
        {iconEnd}
      </>
    );
  };

  const buttonElement = (
    <FBButton
      className={className}
      {...props}
      // @ts-ignore
      onClick={(e) => {
        if (stopPropagation) {
          e?.stopPropagation();
          e?.preventDefault();
        }
        if (onClick) {
          if (confirm) {
            setShowConfirmModal(true);
          } else {
            onClick(e);
          }
        }
      }}
    >
      {loading && <Spinner />}
      {!loading && getChild()}
    </FBButton>
  );

  const confirmModal = confirm && onClick && (
    <ButtonConfirmation
      {...confirm}
      show={showConfirmModal}
      close={() => setShowConfirmModal(false)}
      onAccept={onClick}
    />
  );

  return (
    <>
      {confirmModal}
      {toolTip ? (
        <ToolTip content={toolTip}>{buttonElement}</ToolTip>
      ) : (
        buttonElement
      )}
    </>
  );
}

/* eslint-disable */

import { useHttp } from '~/core/http';


import { createExperience, CreateExperienceArgs, createSeries, CreateSeriesArgs, deleteSchedule, DeleteScheduleArgs, fetchAddress, FetchAddressArgs, fetchExperiences, FetchExperiencesArgs, fetchSeries, FetchSeriesArgs, fetchSeriesGuests, FetchSeriesGuestsArgs, fetchSeriesMetrics, FetchSeriesMetricsArgs, listSchedules, ListSchedulesArgs, listSeries, ListSeriesArgs, updateAddress, UpdateAddressArgs, updateSchedule, UpdateScheduleArgs, updateSeries, UpdateSeriesArgs, uploadPhoto, UploadPhotoArgs } from './PlaygroundHostsSeries.client';

export const createExperienceHook = (immediateCall: boolean = true, initArgs?: CreateExperienceArgs) => {
  return useHttp(createExperience, { immediateCall, initArgs })
};
export const createSeriesHook = (immediateCall: boolean = true, initArgs?: CreateSeriesArgs) => {
  return useHttp(createSeries, { immediateCall, initArgs })
};
export const deleteScheduleHook = (immediateCall: boolean = true, initArgs?: DeleteScheduleArgs) => {
  return useHttp(deleteSchedule, { immediateCall, initArgs })
};
export const fetchAddressHook = (immediateCall: boolean = true, initArgs?: FetchAddressArgs) => {
  return useHttp(fetchAddress, { immediateCall, initArgs })
};
export const fetchExperiencesHook = (immediateCall: boolean = true, initArgs?: FetchExperiencesArgs) => {
  return useHttp(fetchExperiences, { immediateCall, initArgs })
};
export const fetchSeriesHook = (immediateCall: boolean = true, initArgs?: FetchSeriesArgs) => {
  return useHttp(fetchSeries, { immediateCall, initArgs })
};
export const fetchSeriesGuestsHook = (immediateCall: boolean = true, initArgs?: FetchSeriesGuestsArgs) => {
  return useHttp(fetchSeriesGuests, { immediateCall, initArgs })
};
export const fetchSeriesMetricsHook = (immediateCall: boolean = true, initArgs?: FetchSeriesMetricsArgs) => {
  return useHttp(fetchSeriesMetrics, { immediateCall, initArgs })
};
export const listSchedulesHook = (immediateCall: boolean = true, initArgs?: ListSchedulesArgs) => {
  return useHttp(listSchedules, { immediateCall, initArgs })
};
export const listSeriesHook = (immediateCall: boolean = true, initArgs?: ListSeriesArgs) => {
  return useHttp(listSeries, { immediateCall, initArgs })
};
export const updateAddressHook = (immediateCall: boolean = true, initArgs?: UpdateAddressArgs) => {
  return useHttp(updateAddress, { immediateCall, initArgs })
};
export const updateScheduleHook = (immediateCall: boolean = true, initArgs?: UpdateScheduleArgs) => {
  return useHttp(updateSchedule, { immediateCall, initArgs })
};
export const updateSeriesHook = (immediateCall: boolean = true, initArgs?: UpdateSeriesArgs) => {
  return useHttp(updateSeries, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};

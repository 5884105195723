import { Article, UserAvatar, Card } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Host } from '~/generated/models/Host';

type HostDetailsProps = {
  host: Host;
};
export default function HostDetails({ host }: HostDetailsProps) {
  return (
    <Article>
      <Card>
        <div className="flex items-center justify-between w-full">
          <h2 className="text-left">{host.friendlyName}</h2>
          <div className="flex items-center">
            <UserAvatar host={host} imageSize="lg" expandable />
          </div>
        </div>

        <h3>Mission Statement</h3>
        <strong>Summary</strong>
        {host.summary}

        <strong>About</strong>
        {host.about}
      </Card>
    </Article>
  );
}

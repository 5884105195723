import { dater } from '@cg/common/src/utils/DateUtils';
import { UserId } from '@cg/common/src/ids';
import { DetailedExperience } from '~/generated/models/DetailedExperience';

const PREFIX = '@@cg-token-management@@';
const ACCESS_TOKEN = `${PREFIX}/access-token`;
const LAST_LOGGED = `${PREFIX}/last-logged`;

const factory = <T extends Object>(
  key: string,
  override = {
    setter: (item: T) => item.toString(),
    getter: (item: string | null) => item as T | null,
  },
) => {
  const obj = {
    get: (): T => {
      return override.getter(localStorage.getItem(key)) as T;
    },
    set: (item: T) => {
      localStorage.setItem(key, override.setter(item));
    },
    clear: () => {
      localStorage.setItem(key, '');
    },
    has: () => {
      return false;
    },
  };

  obj.has = () => {
    return obj.get() !== null && !!obj.get();
  };

  return obj;
};

const lastLoggedStorage = () => {
  const obj = {
    get: () => {
      return localStorage.getItem(LAST_LOGGED);
    },
    set: (date: Date) => {
      localStorage.setItem(LAST_LOGGED, date.toUTCString());
    },
    isToday: () => {
      const lastLogged = obj.get();
      if (!lastLogged) {
        return false;
      }

      return dater(lastLogged).isToday();
    },
  };

  return obj;
};
export const lastLogged = lastLoggedStorage();

const accessTokenStorage = () => {
  const obj = {
    get: () => {
      return localStorage.getItem(ACCESS_TOKEN);
    },
    set: (token: string) => {
      localStorage.setItem(ACCESS_TOKEN, token);
    },
    clear: () => {
      localStorage.setItem(ACCESS_TOKEN, '');
    },
    has: () => {
      return false;
    },
  };

  obj.has = () => {
    return obj.get() !== null && obj.get() !== '';
  };

  return obj;
};
export const accessToken = accessTokenStorage();

export const hasAccessToken = () => {
  return accessToken.has();
};

const TICKET_PURCHASER = `${PREFIX}/ticket-purchaser`;
export const ticketPurchaser = factory<UserId>(TICKET_PURCHASER, {
  setter: (item) => item.toString(),
  getter: (item) => {
    if (!item) {
      return null;
    }

    return new UserId(item);
  },
});

const RUN_CLUB_REGISTRATION = `${PREFIX}/run-club-registration`;
export const runClubRegistration = factory<DetailedExperience[]>(
  RUN_CLUB_REGISTRATION,
  {
    setter: (item) => JSON.stringify(item),
    getter: (item) => {
      if (!item) {
        return [];
      }

      return JSON.parse(item) as DetailedExperience[];
    },
  },
);

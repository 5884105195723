import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../buttons';

function GetStarted() {
  return (
    <div className="flex justify-between items-center my-10 w-full">
      <span className="text-primary text-xl font-bold">
        Create an Experience
      </span>
      <Link to="/become-a-host">
        <Button color="primary" className="text-white font-semibold">
          Get Started
        </Button>
      </Link>
    </div>
  );
}

export default GetStarted;

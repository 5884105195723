import * as React from 'react';
import { MouseEvent, useState } from 'react';
import { Modal } from 'flowbite-react';
// eslint-disable-next-line import/no-cycle
import Button from './Button';

export type ConfirmProps = {
  header: string;
  body: React.ReactNode;
  accept: {
    text: string;
  };
  reject: {
    text: string;
  };
};
type Props = ConfirmProps & {
  show: boolean;
  close: () => void;
  onAccept: (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<boolean | string | void> | void;
};

function ButtonConfirmation({
  header,
  body,
  accept,
  reject,
  onAccept,
  show,
  close,
}: Props) {
  const [acceptingConfirmation, setAcceptingConfirmation] = useState(false);
  const [error, setError] = useState('');
  const onClose = () => {
    setError('');
    setAcceptingConfirmation(false);
    close();
  };

  return (
    <Modal show={show} onClose={onClose} size="lg">
      <Modal.Header>
        {error ? 'Sorry, something went wrong' : header}
      </Modal.Header>
      <Modal.Body>{error || body}</Modal.Body>
      <Modal.Footer>
        {error ? (
          <Button color="primary" onClick={onClose} className="ml-auto w-full">
            Got it
          </Button>
        ) : (
          <>
            <Button
              disabled={acceptingConfirmation}
              color="secondary"
              onClick={onClose}
              className="ml-auto flex-1"
            >
              {reject.text}
            </Button>
            <Button
              loading={acceptingConfirmation}
              color="primary"
              className="ml-auto flex-1"
              onClick={async (event) => {
                setAcceptingConfirmation(true);
                // @ts-ignore
                const response = await onAccept(event);
                setAcceptingConfirmation(false);
                if (typeof response === 'string') {
                  setError(response);
                } else {
                  onClose();
                }
              }}
            >
              {accept.text}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ButtonConfirmation.displayName = 'ButtonConfirmation';

export default ButtonConfirmation;
